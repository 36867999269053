import styles from './styles.module.css';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { CSSProperties, ReactNode, ComponentType } from 'react';
import { Video } from 'src/components/video';
import { CaretIcon } from 'src/icons/caret';

type ImageData = {
	src?: string;
	style?: CSSProperties;
	imageContainerStyle?: CSSProperties;
};

type IconData = {
	Icon?: ComponentType<any>;
	style?: CSSProperties;
	iconContainerStyle?: CSSProperties;
};

type TitleData = {
	leftImage?: string; // see about page, board of directors
	titleJsx?: ReactNode; // to give more control on how the title should look.
	/** you can either specify a title as JSX or just the text which will get default styling */
	titleText?: string | null;
	subTitle?: string; // see about page, board of directors
	withBorderBottom?: boolean;
};

type LinkSectionData = {
	/** if not specified it falls back to 'READ MORE' */
	readMoreText?: string;
	linkTo?: string;
	externalLink?: string | null;
	/** defaults to false */
	withBorderTop?: boolean;
	/** e.g. VIDEO, or INDUSTRY REPORT. see search results page */
	typeText?: string;
};

interface Props {
	image?: ImageData;
	icon?: IconData;
	title: TitleData;
	publishedDate?: string | null;
	children?: ReactNode;
	asCard?: boolean;
	linkSection: LinkSectionData;
	className?: string;
	style?: CSSProperties;
	/**
	 * if it's a video, pass its URL to get the Thumbnail image from (in case of external videos, e.g. YouTube)
	 */
	asVideoUrl?: string;
}

const getImageJsx = (image: ImageData | undefined, icon: IconData | undefined) => {
	if (image && image.src) {
		return (
			<div className={styles.imageContainer} style={image.imageContainerStyle}>
				<img className={styles.image} src={image?.src} style={image?.style} />
			</div>
		);
	}
	if (icon && icon.Icon) {
		const IconElement = icon.Icon;
		return (
			<div className={styles.imageContainer} style={icon.iconContainerStyle}>
				<div className={styles.iconContainer} style={icon.style}>
					<IconElement />
				</div>
			</div>
		);
	}
	return undefined;
};

const getTitleJsx = (props: TitleData, hasImageTop: boolean) => {
	const { leftImage, titleJsx, titleText, subTitle, withBorderBottom } = props;
	if (titleJsx) {
		return titleJsx;
	}
	if (!titleText /* && !titleJsx */) {
		return undefined;
	}
	const titleOuterContainerCLass = classNames(styles.titleOuterContainer, {
		[styles.titleOuterContainerWithImage]: hasImageTop,
	});
	const titleInnerContainerClass = classNames([
		styles.titleInnerContainer,
		{ [styles.titleWithBorderBottom]: withBorderBottom },
		{ [styles.titleWithImage]: leftImage },
	]);
	const subtitleJsx = subTitle ? <h5 className={styles.subtitle}>{subTitle}</h5> : undefined;
	const titleSectionJsx = (
		<div className={titleInnerContainerClass}>
			<h4 className={styles.title}>{titleText}</h4>
			{subtitleJsx}
		</div>
	);
	const leftImageJsx = leftImage ? (
		<img src={leftImage} className={styles.titleLeftImage} />
	) : undefined;
	return (
		<div className={titleOuterContainerCLass}>
			{leftImageJsx}
			{titleSectionJsx}
		</div>
	);
};

const getLinkSectionJsx = (
	props: LinkSectionData,
	hasImageTop: boolean,
	isVideo: boolean = false
) => {
	const { readMoreText, linkTo, withBorderTop, typeText, externalLink } = props;
	const linkText = readMoreText
		? readMoreText.toUpperCase()
		: isVideo
		? 'WATCH VIDEO'
		: 'READ MORE';
	const typeJsx = typeText ? (
		<span className={styles.readMoreTypeText}>{typeText}</span>
	) : undefined;
	const containerClass = classNames([
		styles.linkSectionContainer,
		{ [styles.linkSectionWithBorderTop]: withBorderTop },
		{ [styles.linkSectionContainerWithImage]: hasImageTop },
		{ [styles.linkSectionContainerWithoutImage]: !hasImageTop },
	]);

	const internalLinkJsx = (
		<AnchorLink to={linkTo || '/'}>
			{linkText} <CaretIcon direction="right" />
		</AnchorLink>
	);

	const externalLinkJsx = (
		<a href={externalLink || '/'} target="_blank">
			{linkText} <CaretIcon direction="right" />
		</a>
	);

	return (
		<div className={containerClass}>
			{linkTo ? internalLinkJsx : externalLinkJsx}
			{typeJsx}
		</div>
	);
};

export const ReadMore = (props: Props) => {
	const {
		image,
		icon,
		title,
		publishedDate,
		children,
		asCard,
		linkSection,
		style,
		className,
		asVideoUrl,
	} = props;

	const wrapperClass = classNames([styles.wrapper, { [styles.wrapperCard]: asCard }, className]);

	const publishedDateJsx = publishedDate ? (
		<div className={styles.published}>Published: {publishedDate}</div>
	) : undefined;

	const videoJsx = (
		<Video
			videoUrl={asVideoUrl || '/'}
			thumbnailImageSrc={image?.src}
			thumbnailStyle={image?.style}
			linkToDetails={{
				linkTo: linkSection.linkTo || linkSection.externalLink || '/',
				targetBlank: Boolean(linkSection.externalLink),
			}}
		/>
	);

	const hasVideoImage = Boolean(asVideoUrl) && (linkSection.externalLink || image?.src);

	const params = {
		image: hasVideoImage ? (
			<div className={styles.imageContainer} style={image?.imageContainerStyle}>
				{videoJsx}
			</div>
		) : (
			getImageJsx(image, icon)
		),
		title: getTitleJsx(title, Boolean(asVideoUrl)),
		linkSection: getLinkSectionJsx(
			linkSection,
			Boolean(asVideoUrl) || image?.src !== undefined || icon?.Icon !== undefined,
			Boolean(asVideoUrl)
		),
	};

	return (
		<div className={wrapperClass} style={style}>
			{params.image}
			{params.title}
			{publishedDateJsx}
			<div className={styles.content}>{children}</div>
			{params.linkSection}
		</div>
	);
};
