import styles from './styles.module.css';
import classNames from 'classnames';
import React, { CSSProperties, useEffect, useState, useRef, SyntheticEvent } from 'react';
import ReactPlayer from 'react-player';
import { CloseIcon, CaretIcon } from 'src/icons';
import { navigate } from 'gatsby';

interface Props {
	videoUrl?: string | null;
	thumbnailImageSrc?: string | null;
	thumbnailStyle?: CSSProperties;
	/**
	 *  if no linkToDetails is provided, a popup will be shown
	 * */

	linkToDetails?: {
		linkTo: string;
		targetBlank?: boolean;
	};
	withoutThumbnail?: boolean; // used on Video Details Page => show video, no thumbnail
	// withPopup?: boolean; // does clicking on the thumbnail shows a pop-up with the video?
}

const VideoWithPopup = (props: Props) => {
	const { thumbnailStyle, videoUrl, thumbnailImageSrc } = props;
	const [isExpanded, setIsExpanded] = useState(false);
	const videoRef = useRef<ReactPlayer>(null);

	useEffect(() => {
		// (when getting an external link, ReactPlayer does a good job bringing up a preview image which we use as preview only)
		// ensure that thumbnail player, which is used for preview image only, is not playable.
		// ideally I would 'onCLick - stopPropagation when preview is clicked, but I could not override ReactPlayer's onClick. Without this, the preview image will play as well as the pop-up player.
		if (videoRef) {
			videoRef.current?.showPreview();
		}
	});

	const onThumbnailClick = () => {
		setIsExpanded(true);
	};

	const onCloseBtnClick = () => {
		setIsExpanded(false);
	};

	const thumbnailJsx = (
		<div className={styles.thumbnailWrapper} onClick={onThumbnailClick} style={thumbnailStyle}>
			<ReactPlayer
				url={videoUrl || undefined}
				light={thumbnailImageSrc || true}
				className={styles.reactPlayerThumbnail}
				width="100%"
				height="100%"
				ref={videoRef}
				playing={false}
			/>
		</div>
	);

	const popUpJsx = (
		<div className={styles.outerContainer}>
			<div className={styles.popupContainer}>
				<div className={styles.headerContainer} id="here">
					<button type="button" onClick={onCloseBtnClick} className={styles.closeIcon}>
						<CloseIcon scale={1} />
					</button>
				</div>
				<div className={styles.videoContainer}>
					<ReactPlayer
						url={videoUrl || undefined}
						className={styles.reactPlayer}
						width="100%"
						height="100%"
						playing
						controls
					/>
				</div>
			</div>
		</div>
	);

	return (
		<>
			{thumbnailJsx}
			{isExpanded ? popUpJsx : undefined}
		</>
	);
};

export const Video = (props: Props) => {
	const { videoUrl, thumbnailImageSrc, thumbnailStyle, linkToDetails, withoutThumbnail } = props;
	const videoRef = useRef<ReactPlayer>(null);

	useEffect(() => {
		// (when getting an external link, ReactPlayer does a good job bringing up a preview image which we use as preview only)
		// ensure that thumbnail player, which is used for preview image only, is not playable.
		// ideally I would 'onCLick - stopPropagation when preview is clicked, but I could not override ReactPlayer's onClick. Without this, the preview image will become a video when onClick.
		if (videoRef) {
			videoRef.current?.showPreview();
		}
	});

	if (withoutThumbnail) {
		return (
			<div className={styles.videoContainer}>
				<ReactPlayer
					url={videoUrl || undefined}
					className={styles.reactPlayer}
					width="100%"
					height="100%"
					controls
				/>
			</div>
		);
	}

	if (!linkToDetails) {
		return <VideoWithPopup {...props} />;
	}

	const onClickNavigate = (event: SyntheticEvent) => {
		event?.preventDefault();
		if (linkToDetails.targetBlank) {
			window.open(linkToDetails.linkTo);
		} else {
			navigate(linkToDetails.linkTo);
		}
	};

	return (
		<div
			className={styles.thumbnailWrapper}
			onClick={(event) => onClickNavigate(event)}
			style={thumbnailStyle}
		>
			<ReactPlayer
				url={videoUrl || undefined}
				light={thumbnailImageSrc || true}
				className={styles.reactPlayerThumbnail}
				width="100%"
				height="100%"
				ref={videoRef}
				playing={false}
			/>
		</div>
	);
};
